import React, {Component, useState, useEffect, useRef} from "react";
import { Button, Table, Card, Form, Col, Dropdown, FormControl, Tabs, Tab, Modal } from 'react-bootstrap';
import {Checkbox} from '@material-ui/core'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ajax from '../utils/ajax'
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import './Products.scss'
import { Paper } from "@material-ui/core";
import Media from '../components/Media2/Media'
import espritSol from '../espritSol.js'

library.add(fas)

const saveBlob = (function () {
  var a = document.createElement("a")
  document.body.appendChild(a)
  a.style = "display: none"
  return function (blob, fileName) {
    var url = window.URL.createObjectURL(blob)
    a.href = url
    a.download = fileName
    a.type = "application/pdf"
    a.click()
    window.URL.revokeObjectURL(url)
  }
}())

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder="Recherche..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.match(new RegExp(value, 'gi')),
          )}
        </ul>
      </div>
    );
  },
);

const ProductForm = (props) => {
  const [state, setState] = useState(props.product || {})
  const handleChange = e => {
    const { name, value } = e.target;
    setState(prevState => ({...prevState, [name]: value}))
  }

  const parentCategoryName = state.category && props.categoriesById[props.categoriesById[state.category].parent].name
  const isPaint = state.category && (parentCategoryName === 'Peinture' || parentCategoryName === 'Enduit, colle & mastic')


  const handleOfferChange = (i, e) => {
    const { name, value } = e.target;

    if (isPaint) {
      setState(prevState => {
        let offers = [...prevState.paintOffers]
        offers[i][name] = value
        return {...prevState, paintOffers: offers}
      })
    } else {
      setState(prevState => {
        let offers = [...prevState.offers]
        offers[i][name] = value
        return {...prevState, offers: offers}
      })
    }
  }

  const handlePaintPriceChange = (price, nuancier, i) => {
    setState(prevState => {
      let offers = [...prevState.paintOffers]
      offers[i].price[nuancier] = price
      return {...prevState, paintOffers: offers}
    })
  }

  const handleOfferUnitChange = (unit, i) => {
    if (isPaint) {
      setState(prevState => {
        let offers = [...prevState.paintOffers]
        offers[i].unit = unit
        return {...prevState, paintOffers: offers}
      })
    } else {
      setState(prevState => {
        let offers = [...prevState.offers]
        offers[i].unit = unit
        return {...prevState, offers: offers}
      })
    }

  }

  const [brands, setBrands] = useState()
  const [brandsById, setBrandsById] = useState({})
  const fetchBrands = () => {
      ajax.get('/api/brands').then(brands => {
        setBrands(brands)
        let byId = {}
        for (let b of brands) {
          byId[b._id] = b
        }
        setBrandsById(byId)
      })
  }
  useEffect(fetchBrands, [])

  const textInput = (field, placeholder, user, disabled) => {
    return <Form.Control type="text" disabled={disabled} placeholder={placeholder} value={state[field] || ''} name={field} onChange={handleChange} />
  }
  const numberInput = (field, placeholder, user) => {
    return <Form.Control type="number" placeholder={placeholder} value={state[field] || ''} name={field} onChange={handleChange} />
  }

  const [brandModal, showBrandModal] = useState(false)

  let categoryItems = []
  for (let rootId in props.categoriesByType) {
    let parentCat = props.categoriesByType[rootId]
    categoryItems = categoryItems.concat(parentCat.children.map(c => (
      <Dropdown.Item key={c._id} active={c._id === state.category} onClick={() => { setState(prevState => ({...prevState, category: c._id}))} }>{parentCat.name + ' > ' + c.name}</Dropdown.Item>
    )))
  }

  const renderGeneralTab = (user) => {
    return (
      <Form>
        <Form.Group>
          <Form.Row>
            <Col sm style={{scrollMarginBottom: '10px'}}>
              <Form.Label>Nom</Form.Label>
              {textInput('name', 'Nom du produit', user)}
            </Col>
            <Col sm style={{marginBottom: '10px'}}>
              <Form.Label>Marque</Form.Label>
              {
                brands && brandsById && (
                  <Dropdown navbar>
                    <Dropdown.Toggle as={CustomToggle} id="brand-dropdown">
                      {(state.brand && brandsById[state.brand]) ? brandsById[state.brand].name : 'Sélectionnez une marque'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu}>
                      {brands.map(b => (<Dropdown.Item key={b._id} active={b._id === state.brand} onClick={() => { setState(prevState => ({...prevState, brand: b._id}))} }>{b.name}</Dropdown.Item>))}
                    </Dropdown.Menu>
                  </Dropdown>
                )
              }
            </Col>
            <Col sm style={{marginBottom: '10px'}}>
              <Form.Label>Catégorie de produit</Form.Label>
              {
                props.categoriesById && (
                  <Dropdown navbar>
                    <Dropdown.Toggle as={CustomToggle} id="category-dropdown">
                      {(state.category && props.categoriesById[state.category]) ? props.categoriesById[state.category].name : 'Sélectionnez une catégorie'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu}>
                      {categoryItems}
                    </Dropdown.Menu>
                  </Dropdown>
                )
              }
            </Col>
          </Form.Row>
        </Form.Group>

        <Form.Group>
          <Form.Row>
            <Col sm style={{marginBottom: '10px'}}>
              <Form.Label>Référence</Form.Label>
              {textInput('reference', 'Référence du produit')}
            </Col>
            <Col sm style={{marginBottom: '10px'}}>
              <Form.Label>Code barre</Form.Label>
              {textInput('barcode', 'Code barre')}
            </Col>
          </Form.Row>
        </Form.Group>

        <Form.Group>
          <Form.Row>
            <Col sm style={{marginBottom: '10px'}}>
              <Form.Label>Longueur emballé (m)</Form.Label>
              {numberInput('length', 'Longueur emballé (m)')}
            </Col>
            <Col sm style={{marginBottom: '10px'}}>
              <Form.Label>Largeur emballé (m)</Form.Label>
              {numberInput('width', 'Largeur emballé (m)')}
            </Col>
            <Col sm style={{marginBottom: '10px'}}>
              <Form.Label>Hauteur emballé (m)</Form.Label>
              {numberInput('height', 'Hauteur emballé (m)')}
            </Col>
            <Col sm style={{marginBottom: '10px'}}>
              <Form.Label>Poids emballé (kg)</Form.Label>
              {numberInput('weight', 'Poids emballé (kg)')}
            </Col>
          </Form.Row>
          <Form.Row style={{marginBottom: '10px'}}>
            <Form.Label>Description</Form.Label>
            <Form.Control as="textarea" rows="15" placeholder="Description du produit (argumentaire commercial, usages typiques, points forts du produit, caractéristiques techniques principales... )" value={state.description || ''} name="description" onChange={handleChange} />
          </Form.Row>
          <Form.Row style={{marginBottom: '10px'}}>
            <Form.Label>Les + produit</Form.Label>
            <Form.Control as="textarea" rows="5" placeholder="Les + produit" value={state.plusProduit || ''} name="plusProduit" onChange={handleChange} />
          </Form.Row>
          <Form.Row style={{marginBottom: '10px'}}>
            <Form.Label>Caractéristiques techniques</Form.Label>
            <Form.Control as="textarea" rows="5" placeholder="Les + produit" value={state.technicalFeatures || ''} name="technicalFeatures" onChange={handleChange} />
          </Form.Row>
          <Form.Row>
            <Form.Label>Conditions d'application</Form.Label>
            <Form.Control as="textarea" rows="5" placeholder="Conditions d'application" value={state.applianceConditions || ''} name="applianceConditions" onChange={handleChange} />
          </Form.Row>
        </Form.Group>
      </Form>
    )
  }

  const renderSimulatorTab = (user) => {
    return (
      <Form>
        <Form.Group>
          <Form.Row>
            <Col sm style={{scrollMarginBottom: '10px'}}>
              <Form.Label>Nom</Form.Label>
              {textInput('name', 'Nom du produit', user, true)}
            </Col>
            <Col sm style={{marginBottom: '10px'}}>
              <Form.Label>Marque</Form.Label>
              {
                brands && brandsById && (
                  <div>Esprit Sol</div>
                )
              }
            </Col>
          </Form.Row>

          <h3 style={{marginTop: "30px"}}>Textures</h3>
          {
            (state.materialoTexture && state.materialoTexture.fromMaterialo)
            ? (
              <div>Texture fournie par Materialo</div>
            )
            : (
              <>
              <b>Instructions :</b>
              <ul>
                <li>Pour un parquet, importer les images de chaque lame de parquet (parfaitement détourées, sans marge ni bordure) composant le produit et préciser les dimensions en mm de la lame.</li>
                <li>Pour les autres types de sol, importer une unique image répétable et préciser les dimensions en mm de la portion de sol représentée par l'image.</li>
                <li>Ne pas oublier de cliquer sur le bouton "Enregistrer" après l'édition.</li>
              </ul>
              <b>Bonnes pratiques relatives aux images importées :</b>
              <ul>
                <li>Les visuels doivent être en vue de dessus parfaitement perpendiculaire au sol.</li>
                <li>L'éclairage des images doit être uniforme, sans ombre ni reflet.</li>
                <li>Utiliser la meilleure résolution disponible.</li>
                <li>Le ratio largeur/hauteur de l'image doit correspondre au ratio largeur/hauteur saisi.</li>
              </ul>

              <Form.Row style={{marginTop: "20px"}}>
                <Col sm style={{scrollMarginBottom: '10px'}}>
                  <Form.Label>Largeur (mm)</Form.Label>
                  {numberInput('width', 'Largeur', user)}
                </Col>
                <Col sm style={{scrollMarginBottom: '10px'}}>
                  <Form.Label>Hauteur (mm)</Form.Label>
                  {numberInput('height', 'Hauteur', user)}
                </Col>
              </Form.Row>

              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '15px', marginTop: '20px'}}>
                <Button style={{flexShrink: 0, marginRight: '15px'}} variant="primary" onClick={openImageInput}>
                  <FontAwesomeIcon icon='plus' style={{marginRight: '15px'}} />
                  Ajouter une image
                </Button>
                <input type='file' accept="image/png, image/jpeg, image/jpg" ref={imageInputRef} style={{display: 'none'}} onChange={addImage} />
              </div>
              <div className='media-gallery'>
                {uploading && <div>Envoi en cours... Veuillez patienter</div>}
                {!uploading && state.medias && state.medias.map((media, i) =>
                  <Media _id={media} key={media} type='image' onRemove={removeImage.bind(null, i)} />
                )}
              </div>
              </>
            )
          }
        </Form.Group>


      </Form>
    )
  }

  const toggleDestination = (dest) => {
    let destinations = [...state.destinations]
    let index = destinations.indexOf(dest)
    if (index < 0) destinations.push(dest)
    else destinations.splice(index, 1)
    setState(prevState => ({...prevState, destinations: destinations}))
  }

  const hasDestination = (dest) => {
    if (!state.destinations) {
      setState(prevState => ({...prevState, destinations: []}))
      return false
    }
    return state.destinations.indexOf(dest) >= 0
  }

  const toggleLabel = (label) => {
    let labels = [...state.labels]
    let index = labels.indexOf(label)
    if (index < 0) labels.push(label)
    else labels.splice(index, 1)
    setState(prevState => ({...prevState, labels: labels}))
  }

  const hasLabel = (label) => {
    if (!state.labels) {
      setState(prevState => ({...prevState, labels: []}))
      return false
    }
    return state.labels.indexOf(label) >= 0
  }

  const toggleNuancier = (nuancier) => {
    let nuanciers = [...state.nuanciers]
    let index = nuanciers.indexOf(nuancier)
    if (index < 0) nuanciers.push(nuancier)
    else nuanciers.splice(index, 1)
    setState(prevState => ({...prevState, nuanciers: nuanciers}))
  }

  const hasNuancier = (nuancier) => {
    if (!state.nuanciers) {
      setState(prevState => ({...prevState, nuanciers: []}))
      return false
    }
    return state.nuanciers.indexOf(nuancier) >= 0
  }

  const addOffer = (offer) => {
    let defaultUnit = isPaint ? 'L' : 'pce.'
    const newOffer = {quantity: 1, unit: defaultUnit, price: (isPaint ? {'blanc': 0, 'pastel': 0, 'vive': 0} : 0)}

    if (!isPaint) {
      if (!state.offers) {
        setState(prevState => ({...prevState, offers: [newOffer]}))
      } else {
        setState(prevState => ({...prevState, offers: [...prevState.offers, newOffer]}))
      }
    } else {
      if (!state.paintOffers) {
        setState(prevState => ({...prevState, paintOffers: [newOffer]}))
      } else {
        setState(prevState => ({...prevState, paintOffers: [...prevState.paintOffers, newOffer]}))
      }
    }

  }

  const removeOffer = (i) => {
    if (isPaint) {
      setState(prevState => {
        let offers = [...prevState.paintOffers]
        offers.splice(i, 1)
        return ({...prevState, paintOffers: offers})

      })
    } else {
      setState(prevState => {
        let offers = [...prevState.offers]
        offers.splice(i, 1)
        return ({...prevState, offers: offers})
      })
    }

  }

  const renderPaintTab = (user) => {
    return (
      <Form>
        <Form.Group>
          <Form.Row>
            <Col sm style={{marginBottom: '10px'}}>
              <Form.Label>Rendement min. (m²/L)</Form.Label>
              {numberInput('rendementMin', 'Rendement min. (m²/L)')}
            </Col>
            <Col sm style={{marginBottom: '10px'}}>
              <Form.Label>Rendement max. (m²/L)</Form.Label>
              {numberInput('rendementMax', 'Rendement max. (m²/L)')}
            </Col>
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Row>
            <Form.Label style={{marginBottom: 0, marginRight: '1rem'}}>Usages :</Form.Label>
            <Form.Check inline checked={state.interieur} onClick={e => setState(prevState => ({...prevState, interieur: !state.interieur}))} type="checkbox" label="Usage intérieur" />
            <Form.Check inline checked={state.exterieur} onClick={e => setState(prevState => ({...prevState, exterieur: !state.exterieur}))} type="checkbox" label="Usage extérieur" />
            <Form.Check inline checked={state.impression} onClick={e => setState(prevState => ({...prevState, impression: !state.impression}))} type="checkbox" label="Couche d'impression" />
            <Form.Check inline checked={state.primaire} onClick={e => setState(prevState => ({...prevState, primaire: !state.primaire}))} type="checkbox" label="Primaire" />
            <Form.Check inline checked={state.fixateur} onClick={e => setState(prevState => ({...prevState, fixateur: !state.fixateur}))} type="checkbox" label="Fixateur" />
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Row>
            <Form.Label style={{marginBottom: 0, marginRight: '1rem'}}>Classe d'émission de COV :</Form.Label>
            <div>
              <Form.Check inline checked={state.cov === 'A+'} onClick={e => setState(prevState => ({...prevState, cov: 'A+'}))} type="radio" label="A+" />
              <Form.Check inline checked={state.cov === 'A'} onClick={e => setState(prevState => ({...prevState, cov: 'A'}))} type="radio" label="A" />
              <Form.Check inline checked={state.cov === 'B'} onClick={e => setState(prevState => ({...prevState, cov: 'B'}))} type="radio" label="B" />
              <Form.Check inline checked={state.cov === 'C'} onClick={e => setState(prevState => ({...prevState, cov: 'C'}))} type="radio" label="C" />
              <Form.Check inline checked={state.cov === 'n/a'} onClick={e => setState(prevState => ({...prevState, cov: 'n/a'}))} type="radio" label="Non concerné" />
            </div>
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Row>
            <Form.Label style={{marginBottom: 0, marginRight: '1rem'}}>Destinations :</Form.Label>
            <div>
              <Form.Check inline checked={hasDestination('murs')} onClick={toggleDestination.bind(null, 'murs')} type="checkbox" label="Murs" />
              <Form.Check inline checked={hasDestination('plafonds')} onClick={toggleDestination.bind(null, 'plafonds')} type="checkbox" label="Plafonds" />
              <Form.Check inline checked={hasDestination('radiateurs')} onClick={toggleDestination.bind(null, 'radiateurs')} type="checkbox" label="Radiateurs" />
              <Form.Check inline checked={hasDestination('boiseries')} onClick={toggleDestination.bind(null, 'boiseries')} type="checkbox" label="Boiseries" />
              <Form.Check inline checked={hasDestination('facades')} onClick={toggleDestination.bind(null, 'facades')} type="checkbox" label="Façades" />
              <Form.Check inline checked={hasDestination('metaux')} onClick={toggleDestination.bind(null, 'metaux')} type="checkbox" label="Métaux" />
              <Form.Check inline checked={hasDestination('sol')} onClick={toggleDestination.bind(null, 'sol')} type="checkbox" label="Sol" />
              <Form.Check inline checked={hasDestination('autres')} onClick={toggleDestination.bind(null, 'autres')} type="checkbox" label="Autres" />
            </div>
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Row>
            <Form.Label style={{fontWeight: 'bold'}} style={{marginBottom: 0, marginRight: '1rem'}}>Teintes applicables :</Form.Label>
            <div>
              <Form.Check inline checked={hasNuancier('blanc')} onClick={toggleNuancier.bind(null, 'blanc')} type="checkbox" label="Blanc" />
              <Form.Check inline checked={hasNuancier('pastel')} onClick={toggleNuancier.bind(null, 'pastel')} type="checkbox" label="Teintes pastel" />
              <Form.Check inline checked={hasNuancier('vive')} onClick={toggleNuancier.bind(null, 'vive')} type="checkbox" label="Teintes vives" />
              <Form.Check inline checked={hasNuancier('incolore')} onClick={toggleNuancier.bind(null, 'incolore')} type="checkbox" label="Incolore" />
            </div>
          </Form.Row>
        </Form.Group>
        <Form.Group>
          <Form.Row>
            <Form.Label style={{fontWeight: 'bold'}} style={{marginBottom: 0, marginRight: '1rem'}}>Labels :</Form.Label>
            <div>
            <Form.Check inline checked={hasLabel('excellplus')} onClick={toggleLabel.bind(null, 'excellplus')} type="checkbox" label="Excell +" />
            </div>
          </Form.Row>
        </Form.Group>
      </Form>
    )
  }

  const imageInputRef = useRef()
  const technicalSheetInputRef = useRef()
  const securitySheetInputRef = useRef()
  const fdesInputRef = useRef()
  const openImageInput = () => {
    if (imageInputRef) {
      imageInputRef.current.click()
    }
  }
  const openSecuritySheetInput = () => {
    if (securitySheetInputRef) {
      securitySheetInputRef.current.click()
    }
  }
  const openTechnicalSheetInput = () => {
    if (technicalSheetInputRef) {
      technicalSheetInputRef.current.click()
    }
  }
  const openFDESInput = () => {
    if (technicalSheetInputRef) {
      fdesInputRef.current.click()
    }
  }
  const [uploading, setUploading] = useState(false)
  const addImage = (e) => {
    let data = new FormData()
    data.append('file', e.target.files[0])

    setUploading(true)
    ajax.put('/api/media', data).then((media) => {
      setUploading(false)
      let medias = state.medias ? [...state.medias] : []
      medias.push(media._id)
      setState(prevState => ({...prevState, medias: medias}))
    })
  }
  const setSheet = (type, e) => {
    let data = new FormData()
    data.append('file', e.target.files[0])
    ajax.put('/api/document', data).then((media) => {
      setState(prevState => ({...prevState, [type]: media._id}))
    })
  }
  const removeImage = (i) => {
    let medias = [...state.medias]
    medias.splice(i, 1)
    setState(prevState => ({...prevState, medias: medias}))
  }

  const downloadTechnicalSheet = () => {
    ajax.get('/api/document/' + state.technicalSheet, {responseType: 'arraybuffer'}).then(res => {
      const blob = new Blob([res], { type: 'application/pdf' })
      saveBlob(blob, state.name + ' - Fiche technique.pdf')
    })
  }

  const downloadSecuritySheet = () => {
    ajax.get('/api/document/' + state.securitySheet, {responseType: 'arraybuffer'}).then(res => {
      const blob = new Blob([res], { type: 'application/pdf' })
      saveBlob(blob, state.name + ' - Fiche sécurité.pdf')
    })
  }

  const downloadFDES = () => {
    ajax.get('/api/document/' + state.fdes, {responseType: 'arraybuffer'}).then(res => {
      const blob = new Blob([res], { type: 'application/pdf' })
      saveBlob(blob, state.name + ' - Fiche sécurité environnementale.pdf')
    })
  }

  const renderMediaTab = (user) => {
    return (
      <div>
        <h3>Galerie</h3>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '15px'}}>
          <Button style={{flexShrink: 0, marginRight: '15px'}} variant="primary" onClick={openImageInput}>
            <FontAwesomeIcon icon='plus' style={{marginRight: '15px'}} />
            Ajouter une image
          </Button>
          <input type='file' accept="image/png, image/jpeg, image/jpg" ref={imageInputRef} style={{display: 'none'}} onChange={addImage} />
        </div>
        <div className='media-gallery'>
          {state.medias && state.medias.map((media, i) =>
            <Media _id={media} key={media} type='image' onRemove={removeImage.bind(null, i)} />
          )}
        </div>

        <h3 style={{marginTop: '15px'}}>Fiche technique</h3>
        {state.technicalSheet && <div style={{cursor: 'pointer', color: "#6993FF"}} onClick={downloadTechnicalSheet}>Consulter la fiche</div>}
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '15px'}}>
          <Button style={{flexShrink: 0, marginRight: '15px'}} variant="primary" onClick={openTechnicalSheetInput}>
            <FontAwesomeIcon icon='plus' style={{marginRight: '15px'}} />
            Sélectionner un fichier
          </Button>
          <input type='file' accept="application/pdf" ref={technicalSheetInputRef} style={{display: 'none'}} onChange={setSheet.bind(null, 'technicalSheet')} />
        </div>

        <h3 style={{marginTop: '30px'}}>Fiche de données de sécurité</h3>
        {state.securitySheet && <div style={{cursor: 'pointer', color: "#6993FF"}} onClick={downloadSecuritySheet}>Consulter la fiche</div>}
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '15px'}}>
          <Button style={{flexShrink: 0, marginRight: '15px'}} variant="primary" onClick={openSecuritySheetInput}>
            <FontAwesomeIcon icon='plus' style={{marginRight: '15px'}} />
            Sélectionner un fichier
          </Button>
          <input type='file' accept="application/pdf" ref={securitySheetInputRef} style={{display: 'none'}} onChange={setSheet.bind(null, 'securitySheet')} />
        </div>

        <h3 style={{marginTop: '30px'}}>Fiche de sécurité environnementale</h3>
        {state.fdes && <div style={{cursor: 'pointer', color: "#6993FF"}} onClick={downloadFDES}>Consulter la fiche</div>}
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '15px'}}>
          <Button style={{flexShrink: 0, marginRight: '15px'}} variant="primary" onClick={openFDESInput}>
            <FontAwesomeIcon icon='plus' style={{marginRight: '15px'}} />
            Sélectionner un fichier
          </Button>
          <input type='file' accept="application/pdf" ref={fdesInputRef} style={{display: 'none'}} onChange={setSheet.bind(null, 'fdes')} />
        </div>
      </div>
    )
  }

  const renderPriceTab = (user) => {
    const units = ['L', 'kg', 'g', 'pce.']

    let offers
    if (isPaint) offers = state.paintOffers || []
    else offers = state.offers || []

    return (
      <div>
        <h3>Conditionnements disponibles</h3>
        <Form>
        <Form.Group>
          <div>
            {offers.map((offer, i) => (
              <Form.Row style={{marginTop: '10px'}}>
                <Col>
                  <Form.Label>Quantité / Contenance</Form.Label>
                  <Form.Control type="number" placeholder="Qté. / Contenance" value={offer.quantity || 0} name={'quantity'} onChange={handleOfferChange.bind(null, i)} />
                </Col>
                <Col>
                  <Form.Label>Unité</Form.Label>
                  <Dropdown navbar>
                    <Dropdown.Toggle as={CustomToggle} id="unit-dropdown">
                      {offer.unit || 'Sélectionnez une unité'}
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu}>
                      {units.map(u => (<Dropdown.Item key={u._id} active={u === offer.unit} onClick={handleOfferUnitChange.bind(null, u, i)}>{u}</Dropdown.Item>))}
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                {
                  isPaint
                  ? (
                    <>
                      {
                        (state.nuanciers && state.nuanciers.indexOf('blanc') >= 0) && (
                          <Col>
                            <Form.Label>Prix HT (€) - Blanc</Form.Label>
                            <Form.Control type="number" placeholder="Prix HT (€) - Blanc" value={offer.price.blanc || 0} name={'priceBlanc'} onChange={e => { handlePaintPriceChange(e.target.value, 'blanc', i) }} />
                          </Col>
                        )
                      }
                      {
                        (state.nuanciers && state.nuanciers.indexOf('pastel') >= 0) && (
                          <Col>
                            <Form.Label>Prix HT (€) - Teintes pastel</Form.Label>
                            <Form.Control type="number" placeholder="Prix HT (€) - Teintes pastel" value={offer.price.pastel || 0} name={'pricePastel'} onChange={e => { handlePaintPriceChange(e.target.value, 'pastel', i) }} />
                          </Col>
                        )
                      }
                      {
                        (state.nuanciers && state.nuanciers.indexOf('vive') >= 0) && (
                          <Col>
                            <Form.Label>Prix HT (€) - Teintes vives</Form.Label>
                            <Form.Control type="number" placeholder="Prix HT (€) - Teintes vives" value={offer.price.vive || 0} name={'priceVive'} onChange={e => { handlePaintPriceChange(e.target.value, 'vive', i) }} />
                          </Col>
                        )
                      }
                      {
                        (state.nuanciers && state.nuanciers.indexOf('vive') >= 0) && (
                          <Col>
                            <Form.Label>Prix HT (€) - Incolore</Form.Label>
                            <Form.Control type="number" placeholder="Prix HT (€) - Incolore" value={offer.price.incolore || 0} name={'priceIncolore'} onChange={e => { handlePaintPriceChange(e.target.value, 'incolore', i) }} />
                          </Col>
                        )
                      }
                    </>
                  )
                  : (
                    <Col>
                      <Form.Label>Prix HT (€)</Form.Label>
                      <Form.Control type="number" placeholder="Prix HT (€)" value={offer.price || 0} name={'price'} onChange={handleOfferChange.bind(null, i)} />
                    </Col>
                  )
                }

                <Col style={{alignSelf: "flex-end"}}>
                  <a className='btn btn-icon btn-light btn-sm' onClick={removeOffer.bind(null, i)} style={{marginTop: '10px'}}>
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Minus.svg")}/>
                    </span>
                  </a>
                </Col>
              </Form.Row>
            ))}
            <a className='btn btn-icon btn-light btn-sm' onClick={addOffer} style={{marginTop: '10px'}}>
              <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Plus.svg")}/>
              </span>
            </a>
          </div>
        </Form.Group>
        </Form>
      </div>

    )
  }

  return (
    <div className='Products'>
      <Tabs defaultActiveKey={state.id ? "simulator" : "general"}>
        {
          !state.id && (
            <Tab eventKey='general' title='Général'>
              {renderGeneralTab(props.user)}
            </Tab>
          )
        }
        {
          !state.id && (
            <Tab eventKey='medias' title='Médias'>
              {renderMediaTab(props.user)}
            </Tab>
          )
        }
        {
          isPaint && (
            <Tab eventKey='paint' title={parentCategoryName}>
              {renderPaintTab(props.user)}
            </Tab>
          )
        }
        {
          !state.id && (
            <Tab eventKey='price' title='Conditionnements & Tarifs'>
              {renderPriceTab(props.user)}
            </Tab>
          )
        }
        {
          state.id && (
            <Tab eventKey='simulator' title='Simulateur'>
              {renderSimulatorTab(props.user)}
            </Tab>
          )
        }
      </Tabs>
      <div className='toolbar'>
        {
          !uploading && (
            <Button variant="primary" size="sm" onClick={() => { props.save(state) }}>
              Enregistrer
            </Button>
          )
        }
        <Button size="sm" variant="secondary" onClick={props.cancel}>
          Annuler
        </Button>
      </div>
    </div>
  )
}

export const Products = () => {
  const [editedIndex, setEditedIndex] = useState(null)
  const [editedProduct, setEditedProduct] = useState(null)
  const [removedIndex, setRemovedIndex] = useState(null)
  const [creationMode, setCreationMode] = useState(false)
  const [products, setProducts] = useState(null)
  const [brands, setBrands] = useState(null)
  const [readyToSave, setReadyToSave] = useState(true)
  const [filter, setFilter] = useState('')
  const [filteredProducts, setFilteredProducts] = useState({})
  const [fetching, setFetching] = useState(true)
  const [user, setUser] = useState(null)

  const fetch = () => {
    setFetching(true)
    ajax.get('/api/me').then(user => { setUser(user) })
    ajax.get('/api/materialo_textures').then(materialoTextures => {
      let materialoTexById = {}
      for (let tex of materialoTextures) {
        materialoTexById[tex.id.toString()] = tex
      }

      ajax.get('/api/products').then(p => {
        console.log(espritSol.products.find(a => a.title === "Solpro Compact"))
        let espritSolProducts = [...espritSol.products].map(prod => {
          return {
            _id: prod.id.toString(),
            name: prod.title,
            brand: "5f149e936562434be0245f53",
            enabled: true,
            materialoTexture: materialoTexById[prod.id.toString()],
            ...prod
          }
        })
        p = p.concat(espritSolProducts)
        //p = espritSolProducts
        p.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
        console.log(p)

        setProducts(p)
        if (filter !== '') filterProducts()
        else {
          let filtered = {}
          for (let prod of p) {
            filtered[prod._id] = true
          }
          setFilteredProducts(filtered)
        }

        ajax.get('/api/brands').then(res => {
          let bs = {}
          for (let b of res) {
            bs[b._id] = b
          }
          setBrands(bs)
          setFetching(false)
        })
      })
    })
  }
  useEffect(fetch, [])

  const [categories, setCategories] = useState()
  const [categoriesById, setCategoriesById] = useState({})
  const [categoriesByType, setCategoriesByType] = useState({})
  const fetchCategories = () => {
    ajax.get('/api/categorys').then(cats => {
      setCategories(cats)
      let byId = {}
      let roots = {}
      for (let c of cats) {
        byId[c._id] = c
        if (!c.parent) {
          roots[c._id] = c
          roots[c._id].children = []
        }
      }
      console.log(byId)
      setCategoriesById(byId)
      for (let c of cats) {
        if (c.parent) {
          roots[c.parent].children.push(c)
        }
      }
      for (let rootId in roots) {
        roots[rootId].children.sort((a,b) => a.name.localeCompare(b.name))
      }
      setCategoriesByType(roots)
    })
  }
  useEffect(fetchCategories, [])

  const save = (prod) => {
    /*if (!readyToSave) {
      saveLogo()
      return
    }*/
    if (prod.id) {
      ajax.post('/api/materialo_texture', {
        id: prod.id,
        medias: prod.medias,
        width: prod.width,
        height: prod.height
      }).then(() => {
        fetch()
        setCreationMode(false)
        setEditedIndex(null)
      })
      return
    }

    const method = creationMode ? ajax.put : ajax.post
    if (creationMode) prod.enabled = true
    method('/api/product', prod).then(() => {
      fetch()
      setCreationMode(false)
      setEditedIndex(null)
    })
  }

  /*useEffect(() => {
    if (readyToSave) save()
  }, [readyToSave])*/

  const filterProducts = () => {
    if (!products) return
    if (filter === '') {
      let filtered = {}
      for (let p of products) {
        filtered[p._id] = true
      }
      setFilteredProducts(filtered)
      return
    }
    let filtered = {}
    let rgx = new RegExp(filter, 'gi')
    for (let p of products) {
      if ((p.name && p.name.match(rgx)) || (p.brand && brands[p.brand] && brands[p.brand].name && brands[p.brand].name.match(rgx)) || (p._id && p._id.match(rgx))/* || (p.description && p.description.match(rgx))*/) {
        filtered[p._id] = true
      }
    }
    setFilteredProducts(filtered)
  }

  useEffect(() => {
    filterProducts()
  }, [filter])

  const cancel = () => {
    setCreationMode(false)
    setEditedIndex(null)
  }

  const create = () => {
    setEditedProduct({})
    setCreationMode(true)
  }

  const edit = (i) => {
    let prod = products[i]
    if (prod.materialoTexture) {
      prod = {...prod, ...prod.materialoTexture}
    }
    setEditedProduct(prod)
    setEditedIndex(i)
  }

  const remove = () => {
    ajax.delete('/api/product/' + products[removedIndex]._id).then(() => {
      fetch()
      setRemovedIndex(null)
    })
  }

  const setVisibility = (id, enabled) => {
    ajax.post('/api/product/enabled/' + id, {enabled: enabled}).then(() => {
      let prods = [...products]
      for (let p of prods) {
        if (p._id === id) {
          p.enabled = enabled
          break
        }
      }
      setProducts(prods)
    })
  }

  if (editedIndex !== null || creationMode) {
    return (
      <Card>
        <Card.Body>
          <ProductForm user={user} product={editedProduct} categoriesById={categoriesById} categoriesByType={categoriesByType} cancel={cancel} save={save} />
        </Card.Body>
      </Card>
    )
  }

  const ProductRow = React.memo((p) => {
    let imageUrl = null
    if (!p.id && p.medias && p.medias[0]) imageUrl = "/api/media/thumbnail/" + p.medias[0]
    /*else if (p.images && p.images[0]) imageUrl = "https://esprit-sol.fr/" + p.images[0].path
    else if (p.product_img) imageUrl = "https://esprit-sol.fr/" + p.product_img*/

    return (
      <tr key={p._id} style={p.visible ? undefined : {display: 'none'}}>
        {
          user && (user.role === 'admin' || user.role === 'marketing') && (
            <td><Checkbox checked={p.enabled} disabled={p.id ? true : false} onChange={() => { setVisibility(p._id, !p.enabled) }}/></td>
          )
        }
        <td>{imageUrl && <img height={80} src={imageUrl} />}</td>
        <td>{brands[p.brand] && brands[p.brand].name}</td>
        <td>{p.name}</td>
        <td>{categoriesById[p.category] ? (categoriesById[categoriesById[p.category].parent].name + ' > ' + categoriesById[p.category].name) : "Revêtements de sol"}</td>
        {/*<td style={{whiteSpace: 'pre-wrap'}}>{p.description}</td>*/}
        {
          user && (user.role === 'admin' || user.role === 'marketing') && (
            <td style={{textAlign: 'center'}}>
              <span>{p._id}</span>
              <a className='btn btn-icon btn-light btn-sm' onClick={() => { navigator.clipboard.writeText(p._id) }}>
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <img className='blue-icon' title="Copier l'identifiant" src={toAbsoluteUrl("/media/svg/icons/General/Clipboard.svg")} />
                </span>
              </a>
            </td>
          )
        }
        <td>
          {
            user && (user.role === 'admin' || user.role === 'marketing' || user.role === 'formateur')
            ? (
              <>
              {
                p.materialoTexture && (
                  <a className='btn btn-icon btn-light btn-sm' href={"https://integrate-hbf.materialo.com/v2.1/render_img_scene.php?cnf=comptoirdelours&onam=floor&artnr=" + (p?.materialoTexture?.fromMaterialo || p.id) + "&rot=90&dbg_int=0&pid=T59SBS"} target="_blank">
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <img className='blue-icon' src={toAbsoluteUrl("/media/svg/icons/Home/Picture.svg")} />
                    </span>
                  </a>
                )
              }
                <a className='btn btn-icon btn-light btn-sm' onClick={edit.bind(null, p.index)}>
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <img className='blue-icon' src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")} />
                  </span>
                </a>
                {
                  !p.id && (
                    <a className='btn btn-icon btn-light btn-sm' onClick={setRemovedIndex.bind(null, p.index)}>
                      <span className="svg-icon svg-icon-md svg-icon-primary">
                        <img className='blue-icon' src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")}/>
                      </span>
                    </a>
                  )
                }
              </>
            )
            : (null/*
              <a className='btn btn-icon btn-light btn-sm' onClick={edit.bind(null, p.index)}>
                <span className="svg-icon svg-icon-md svg-icon-primary">
                  <img className='blue-icon' src={toAbsoluteUrl("/media/svg/icons/Navigation/Angle-Right.svg")} />
                </span>
              </a>
            */)
          }
        </td>
      </tr>
    )
  }, (prevProps, nextProps) => (
    prevProps._id === nextProps._id
    && prevProps.enabled === nextProps.enabled
    && prevProps.enabled === nextProps.enabled
    && prevProps.brand === nextProps.brand
    && prevProps.name === nextProps.name
    && prevProps.category === nextProps.category
    && prevProps.description === nextProps.description
    && prevProps.medias[0] === nextProps.medias[0]
  ))

  return (
    <>
      <Card>
        <Card.Body>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '15px'}}>
            {
              user && (user.role === 'admin' || user.role === 'marketing' || user.role === 'formateur') && (
                <Button style={{flexShrink: 0, marginRight: '15px'}} variant="primary" onClick={setCreationMode.bind(null, true)}>
                  <FontAwesomeIcon icon='plus' style={{marginRight: '15px'}} />
                  Ajouter un produit
                </Button>
              )
            }
            <Form.Control type="text" placeholder={'Rechercher...'} value={filter || ''} onChange={(e) => {setFilter(e.target.value)}} />
          </div>

          <Table responsive className="table table-head-custom table-head-bg table-vertical-center">
            <thead>
              <tr className='text-left text-uppercase'>
                {
                  user && (user.role === 'admin' || user.role === 'marketing') && <th><span className='text-dark-75'>Publication</span></th>
                }
                <th><span className='text-dark-75'>Image</span></th>
                <th style={{minWidth: '100px'}}><span className='text-dark-75'>Marque</span></th>
                <th style={{minWidth: '150px'}}><span className='text-dark-75'>Nom</span></th>
                <th style={{minWidth: '150px'}}><span className='text-dark-75'>Catégorie</span></th>
                {/*<th><span className='text-dark-75'>Description</span></th>*/}
                {
                  user && (user.role === 'admin' || user.role === 'marketing') && <th><span className='text-dark-75'>ID</span></th>
                }
                <th style={{minWidth: '105px'}}><span className='text-dark-75'>Actions</span></th>
              </tr>
            </thead>
            <tbody>
              {
                !fetching && filteredProducts && brands && categoriesById && products.map((p, i) => (
                  <ProductRow {...p} index={i} key={p._id} visible={filteredProducts[p._id]} />
                ))
              }
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Modal show={removedIndex !== null}>
        <Modal.Header>
          <Modal.Title>Suppression d'un produit</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {removedIndex !== null && <p>{"Voulez-vous vraiment supprimer le produit " + products[removedIndex].name + " ?"}</p>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={setRemovedIndex.bind(null, null)}>Annuler</Button>
          <Button variant="primary" onClick={remove}>Supprimer</Button>
        </Modal.Footer>
      </Modal>
    </>

  )
}
